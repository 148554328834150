// src/components/RaceMap.tsx
import React, { useState } from 'react';
import { Typography, Box, Tooltip } from '@mui/material';

interface RunnerPosition {
    runner: number;
    offset: number;
    value: number;
}

interface VPaceData {
    type: string;
    data: RunnerPosition[];
}

interface RacePaceData {
    type: string;
    data: string;
}

interface RunnerInfo {
    number: number;
    name: string;
    odds: number;
}

interface RaceMapProps {
    vpace: VPaceData[];
    racepace: RacePaceData[];
    runners: RunnerInfo[];
}

const RaceMap: React.FC<RaceMapProps> = ({ vpace, racepace, runners }) => {
    const [hoveredRunner, setHoveredRunner] = useState<number | null>(null);

    const sortRunners = (runners: RunnerPosition[], type: string) => {
        if (type.toLowerCase() === 'comb') {
            return [...runners].sort((a, b) => b.value - a.value);
        }
        return runners;
    };

    const calculateBounds = (data: RunnerPosition[]) => {
        let minValue = Infinity, maxValue = -Infinity;
        let minOffset = Infinity, maxOffset = -Infinity;

        data.forEach(runner => {
            minValue = Math.min(minValue, runner.value);
            maxValue = Math.max(maxValue, runner.value);
            minOffset = Math.min(minOffset, runner.offset);
            maxOffset = Math.max(maxOffset, runner.offset);
        });

        return { minValue, maxValue, minOffset, maxOffset };
    };


    const generateAxisLabels = (min: number, max: number): number[] => {
        const step = (max - min) / 4;
        return [min, min + step, min + 2 * step, min + 3 * step, max].map(v => parseFloat(v.toFixed(2)));
    };
    return (
        <Box>
            {vpace.map((vp, index) => {
                const bounds = calculateBounds(vp.data);
                const valueRange = bounds.maxValue - bounds.minValue;
                const offsetRange = bounds.maxOffset - bounds.minOffset;
                const xAxisLabels = generateAxisLabels(bounds.minValue, bounds.maxValue);

                return (
                    <Box key={index} mb={4}>
                        <Typography variant="h6" gutterBottom>
                            {vp.type} Map
                        </Typography>
                        <Box sx={{ position: 'relative', width: '100%' }}>
                            <Box
                                sx={{
                                    position: 'relative',
                                    width: '100%',
                                    height: 200,
                                    border: '1px solid #ccc',
                                    borderRadius: '4px 4px 0 0',
                                    overflow: 'hidden',
                                }}
                            >
                                {sortRunners(vp.data, vp.type).map((runner) => {
                                    const runnerInfo = runners.find(r => r.number === runner.runner);
                                    const xPosition = vp.type.toLowerCase() === 'comb'
                                        ? ((bounds.maxValue - runner.value) / valueRange) * 85
                                        : ((runner.value - bounds.minValue) / valueRange) * 85;

                                    const yPosition = 80 - (5 + ((runner.offset - bounds.minOffset) / offsetRange) * 70);

                                    return (
                                        <Tooltip
                                            key={runner.runner}
                                            title={runnerInfo ? `${runnerInfo.name} (Odds: ${runnerInfo.odds.toFixed(2)}) - **${runner.value}**` : `**${runner.value}**`}
                                            arrow
                                        >
                                            <Box
                                                sx={{
                                                    position: 'absolute',
                                                    left: `${xPosition}%`,
                                                    top: `${yPosition}%`,
                                                    width: 120,
                                                    height: 40,
                                                    borderRadius: '4px',
                                                    backgroundColor: `hsl(${runner.runner * 30}, 70%, 50%)`,
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    color: 'white',
                                                    fontWeight: 'bold',
                                                    cursor: 'pointer',
                                                    transform: hoveredRunner === runner.runner ? 'scale(1.1)' : 'scale(1)',
                                                    transition: 'transform 0.2s',
                                                    fontSize: '0.75rem',
                                                    lineHeight: 1.2,
                                                    padding: '2px',
                                                }}
                                                onMouseEnter={() => setHoveredRunner(runner.runner)}
                                                onMouseLeave={() => setHoveredRunner(null)}
                                            >
                                                <div>{runner.runner} | {runner.value}</div>
                                                <div>{runnerInfo?.name || 'Unknown'}</div>
                                            </Box>
                                        </Tooltip>
                                    );
                                })}
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    padding: '0 5%',
                                    borderLeft: '1px solid #ccc',
                                    borderRight: '1px solid #ccc',
                                    borderBottom: '1px solid #ccc',
                                    borderRadius: '0 0 4px 4px',
                                }}
                            >
                                {xAxisLabels.map((label, i) => (
                                    <Typography
                                        key={i}
                                        variant="caption"
                                        sx={{
                                            position: 'absolute',
                                            left: i === 0 ? '0%' : i === xAxisLabels.length - 1 ? 'auto' : `${(i / (xAxisLabels.length - 1)) * 100}%`,
                                            right: i === xAxisLabels.length - 1 ? '0%' : 'auto',
                                            transform: i === 0 ? 'translateX(0%)' : i === xAxisLabels.length - 1 ? 'translateX(0%)' : 'translateX(-50%)',
                                            textAlign: 'center',
                                        }}
                                    >
                                        {label}
                                    </Typography>
                                ))}
                            </Box>
                        </Box>
                    </Box>
                );
            })}
            <Box mt={4}>
                <Typography variant="h6" gutterBottom>
                    Race Pace Calculations
                </Typography>
                {racepace.map((rp, index) => (
                    <Typography key={index} gutterBottom>
                        <strong>{rp.type}:</strong> {rp.data}
                    </Typography>
                ))}
            </Box>
        </Box>
    );
};

export default RaceMap;
