import React from 'react';
import { Box, Typography, Paper } from '@mui/material';
import SportsIcon from '@mui/icons-material/Sports';

const JockeysPage = () => {
    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            height="80vh"
        >
            <Paper elevation={3} sx={{ p: 4, borderRadius: 2, textAlign: 'center' }}>
                <SportsIcon sx={{ fontSize: 60, color: 'primary.main', mb: 2 }} />
                <Typography variant="h4" component="h1" gutterBottom>
                    Jockeys Information Coming Soon
                </Typography>
                <Typography variant="body1" color="text.secondary">
                    We're compiling comprehensive jockey profiles and statistics.
                    Check back soon for exciting updates!
                </Typography>
            </Paper>
        </Box>
    );
};

export default JockeysPage;
