// src/App.tsx
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import { AuthProvider } from './context/AuthContext';
import Navigation from './components/Navigation';
import ProtectedRoute from './components/ProtectedRoute';
import HomePage from './pages/HomePage';
import LoginPage from './pages/LoginPage';
import RacesPage from './pages/RacesPage';
import RaceDetailsPage from './pages/RaceDetailsPage';
import RunnerDetailsPage from './pages/RunnerDetailsPage';
import MarketsPage from './pages/MarketsPage';
import TrainersPage from './pages/TrainersPage';
import JockeysPage from './pages/JockeysPage';
import UserProfilePage from './pages/UserProfilePage';
import MeetingsPage from './pages/MeetingsPage';

const theme = createTheme();

function App() {
  return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AuthProvider>
          <Router>
            <Navigation />
            <Container maxWidth="lg">
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/meetings" element={<MeetingsPage />} />
                <Route path="/races" element={<RacesPage />} />
                <Route path="/races/:id" element={<RaceDetailsPage />} />
                <Route path="/runners/:id" element={<RunnerDetailsPage />} />
                <Route path="/markets" element={<MarketsPage />} />
                <Route element={<ProtectedRoute />}>
                  <Route path="/trainers" element={<TrainersPage />} />
                  <Route path="/jockeys" element={<JockeysPage />} />
                  <Route path="/profile" element={<UserProfilePage />} />
                  {/* Add other protected routes here */}
                </Route>
              </Routes>
            </Container>
          </Router>
        </AuthProvider>
      </ThemeProvider>
  );
}

export default App;
