import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Link,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    Grid,
} from '@mui/material';
import { getMeetings } from '../services/api';

interface Race {
    id: number;
    number: number;
    name: string;
    startTime: string;
    startTimeDelta: string;
    status: string;
}

interface Meeting {
    name: string;
    code: string;
    date: string;
    races: Race[];
}

const MeetingsPage = () => {
    const [meetings, setMeetings] = useState<Meeting[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [dateFilter, setDateFilter] = useState<'today' | 'tomorrow' | 'custom'>('today');
    const [customDate, setCustomDate] = useState<string>('');
    const [timezone, setTimezone] = useState('Australia/Sydney');

    useEffect(() => {
        const fetchMeetings = async () => {
            try {
                let date: string;
                if (dateFilter === 'today') {
                    date = 'today';
                } else if (dateFilter === 'tomorrow') {
                    date = 'tomorrow';
                } else {
                    date = customDate;
                }

                const data = await getMeetings({ date, timezone });
                setMeetings(data);
                setLoading(false);
            } catch (err) {
                setError('Failed to fetch meetings');
                setLoading(false);
            }
        };

        fetchMeetings();
    }, [dateFilter, customDate, timezone]);

    if (loading) return <Typography>Loading...</Typography>;
    if (error) return <Typography color="error">{error}</Typography>;

    const maxRaces = Math.max(...meetings.map(meeting => meeting.races.length), 10);

    return (
        <>
            <Typography variant="h4" component="h1" gutterBottom>
                Meetings
            </Typography>

            <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item xs={12} sm={4}>
                    <FormControl fullWidth>
                        <InputLabel id="date-filter-label">Date</InputLabel>
                        <Select
                            labelId="date-filter-label"
                            value={dateFilter}
                            onChange={(e) => setDateFilter(e.target.value as 'today' | 'tomorrow' | 'custom')}
                        >
                            <MenuItem value="today">Today</MenuItem>
                            <MenuItem value="tomorrow">Tomorrow</MenuItem>
                            <MenuItem value="custom">Custom Date</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                {dateFilter === 'custom' && (
                    <Grid item xs={12} sm={4}>
                        <TextField
                            label="Custom Date"
                            type="date"
                            value={customDate}
                            onChange={(e) => setCustomDate(e.target.value)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth
                        />
                    </Grid>
                )}
                <Grid item xs={12} sm={4}>
                    <FormControl fullWidth>
                        <InputLabel id="timezone-label">Timezone</InputLabel>
                        <Select
                            labelId="timezone-label"
                            value={timezone}
                            onChange={(e) => setTimezone(e.target.value)}
                        >
                            <MenuItem value="Australia/Sydney">Australia/Sydney</MenuItem>
                            {/* Add more timezone options as needed */}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Meeting</TableCell>
                            {[...Array(maxRaces)].map((_, index) => (
                                <TableCell key={index}>Race {index + 1}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {meetings.map((meeting) => (
                            <TableRow key={meeting.code}>
                                <TableCell>{meeting.name}</TableCell>
                                {[...Array(maxRaces)].map((_, index) => {
                                    const race = meeting.races[index];
                                    return (
                                        <TableCell key={index}>
                                            {race ? (
                                                <Link component={RouterLink} to={`/races/${race.id}`}>
                                                    Race {race.number}: {race.name}<br />
                                                    Status:
                                                    <span className="status-badge" style={{
                                                    backgroundColor: '#e0e0e0',
                                                    padding: '2px 6px',
                                                    borderRadius: '4px',
                                                    fontSize: '0.8em',
                                                    fontWeight: 'bold'
                                                        }}>
                                                        {race.status}
                                                    </span>
                                                </Link>
                                            ) : (
                                                '-'
                                            )}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

export default MeetingsPage;