// src/components/Navigation.tsx
import React from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useAuth } from '../context/AuthContext';

const Navigation = () => {
    const { user, logout } = useAuth();
    const navigate = useNavigate();

    const handleLogout = () => {
        logout();
        navigate('/login');
    };

    return (
        <AppBar position="static">
            <Toolbar>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                    GaitMate
                </Typography>
                <Button color="inherit" component={RouterLink} to="/">Home</Button>
                <Button color="inherit" component={RouterLink} to="/Meetings">Meetings</Button>
                <Button color="inherit" component={RouterLink} to="/races">Races</Button>
                <Button color="inherit" component={RouterLink} to="/markets">Markets</Button>
                <Button color="inherit" component={RouterLink} to="/trainers">Trainers</Button>
                <Button color="inherit" component={RouterLink} to="/jockeys">Jockeys</Button>
                {user ? (
                    <>
                        <Button color="inherit" component={RouterLink} to="/profile">Profile</Button>
                        <Button color="inherit" onClick={handleLogout}>Logout</Button>
                    </>
                ) : (
                    <Button color="inherit" component={RouterLink} to="/login">Login</Button>
                )}
            </Toolbar>
        </AppBar>
    );
};

export default Navigation;
