// src/pages/RacesPage.tsx
import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Link } from '@mui/material';
import { getRaces } from '../services/api';

interface Race {
    id: string;
    uniqueId: string;
    date: string;
    venue: string;
    raceNumber: number;
    class: string;
    tcond: string;
}

const RacesPage = () => {
    const [races, setRaces] = useState<Race[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [page, setPage] = useState(1);
    const [totalRaces, setTotalRaces] = useState(0);
    const [selectedDate, setSelectedDate] = useState<Date | null>(null);
    useEffect(() => {
        const fetchRaces = async () => {
            try {
                const params = {
                    page,
                    limit: 10,
                    date: selectedDate ? selectedDate.toISOString().split('T')[0] : undefined
                };
                const { data, total } = await getRaces(params);
                setRaces(data);
                setTotalRaces(total);
                setLoading(false);
            } catch (err) {
                setError('Failed to fetch races');
                setLoading(false);
            }
        };
        fetchRaces();
    }, [page, selectedDate]);

    if (loading) return <Typography>Loading...</Typography>;
    if (error) return <Typography color="error">{error}</Typography>;

    return (
        <>
            <Typography variant="h4" component="h1" gutterBottom>
                Races
            </Typography>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Date</TableCell>
                            <TableCell>Venue</TableCell>
                            <TableCell>Race Number</TableCell>
                            <TableCell>Class</TableCell>
                            <TableCell>Track Condition</TableCell>
                            <TableCell>Details</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {races.map((race) => (
                            <TableRow key={race.id}>
                                <TableCell>{new Date(race.date).toLocaleDateString()}</TableCell>
                                <TableCell>{race.venue}</TableCell>
                                <TableCell>{race.raceNumber}</TableCell>
                                <TableCell>{race.class}</TableCell>
                                <TableCell>{race.tcond}</TableCell>
                                <TableCell>
                                    <Link component={RouterLink} to={`/races/${race.id}`}>
                                        View Details
                                    </Link>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

export default RacesPage;
