import React from 'react';
import { Box, Typography, Paper } from '@mui/material';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';

const MarketsPage = () => {
    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            height="80vh"
        >
            <Paper elevation={3} sx={{ p: 4, borderRadius: 2, textAlign: 'center' }}>
                <TrendingUpIcon sx={{ fontSize: 60, color: 'primary.main', mb: 2 }} />
                <Typography variant="h4" component="h1" gutterBottom>
                    Markets Coming Soon
                </Typography>
                <Typography variant="body1" color="text.secondary">
                    We're working hard to bring you the latest market information.
                    Stay tuned for updates!
                </Typography>
            </Paper>
        </Box>
    );
};

export default MarketsPage;
