import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, Typography, Box } from '@mui/material';
import { requestLoginCode, login } from '../services/api';
import { useAuth } from '../context/AuthContext';

const LoginPage = () => {
    const [email, setEmail] = useState('');
    const [loginCode, setLoginCode] = useState('');
    const [codeSent, setCodeSent] = useState(false);
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const { setUser } = useAuth();

    const handleRequestCode = async (e: React.FormEvent) => {
        e.preventDefault();
        setError('');
        try {
            await requestLoginCode(email);
            setCodeSent(true);
        } catch (err) {
            setError('Error sending login code. Please try again.');
        }
    };

    const handleLogin = async (e: React.FormEvent) => {
        e.preventDefault();
        setError('');
        try {
            const { token } = await login(email, loginCode);
            localStorage.setItem('authToken', token);
            localStorage.setItem('userEmail', email);
            setUser({ email });
            navigate('/');
        } catch (err) {
            setError('Invalid email or login code');
        }
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                maxWidth: 300,
                margin: 'auto',
                marginTop: 8,
            }}
        >
            <Typography variant="h4" component="h1" gutterBottom>
                Login
            </Typography>
            {!codeSent ? (
                <form onSubmit={handleRequestCode} style={{ width: '100%' }}>
                    <TextField
                        label="Email"
                        type="email"
                        fullWidth
                        margin="normal"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                    <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 3 }}>
                        Request Login Code
                    </Button>
                </form>
            ) : (
                <form onSubmit={handleLogin} style={{ width: '100%' }}>
                    <TextField
                        label="Email"
                        type="email"
                        fullWidth
                        margin="normal"
                        value={email}
                        disabled
                    />
                    <TextField
                        label="Login Code"
                        type="text"
                        fullWidth
                        margin="normal"
                        value={loginCode}
                        onChange={(e) => setLoginCode(e.target.value)}
                        required
                    />
                    <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 3 }}>
                        Login
                    </Button>
                </form>
            )}
            {error && (
                <Typography color="error" sx={{ mt: 2 }}>
                    {error}
                </Typography>
            )}
        </Box>
    );
};

export default LoginPage;
