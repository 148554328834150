import {Dialog, DialogTitle, DialogContent, DialogActions, Button, Tooltip} from '@mui/material';
import Typography from "@mui/material/Typography";

interface RunnerDetailsModalProps {
    open: boolean;
    onClose: () => void;
    runner: Runner | null;
}

interface Runner {
    id: string;
    number: number;
    name: string;
    barrier: number;
    trainer: string;
    jockey: string;
    trainerName: string;
    jockeyName: string;
    form: string;
    rating: number;
    oddsRating: number;
    predRating: number;
    dwrtRating: number;
    status: boolean;
    pbLast15Runs: number;
    pbLast12Runs: number;
    pbLast6Runs: number;
}

const RunnerDetailsModal: React.FC<RunnerDetailsModalProps> = ({ open, onClose, runner }) => {
    if (!runner) return null;
    const formatForm = (form: string): string => {
        return form.split('-').map(entry => {
            const match = entry.match(/(\d+)(\((-?\d+)\))?/);
            if (match) {
                const [, position, , days] = match;
                return days ? `${position}(${days})` : position;
            }
            return entry;
        }).join(' ');
    };


    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{runner.name} (#{runner.number})</DialogTitle>
            <DialogContent>
                <Typography><strong>Barrier:</strong> {runner.barrier}</Typography>
                <Typography><strong>Jockey:</strong> {runner.jockeyName}</Typography>
                <Typography><strong>Trainer:</strong> {runner.trainerName}</Typography>
                <Typography><strong>Form:</strong>{runner.form}</Typography>
                <Typography><strong>Rating:</strong> {runner.rating}</Typography>
                <Typography><strong>Odds Rating:</strong> {runner.oddsRating}</Typography>
                <Typography><strong>Pred Rating:</strong> {runner.predRating}</Typography>
                <Typography><strong>DWRT Rating:</strong> {runner.dwrtRating}</Typography>
                <Typography><strong>PB Last 15 Runs:</strong> {runner.pbLast15Runs}</Typography>
                <Typography><strong>PB Last 12 Runs:</strong> {runner.pbLast12Runs}</Typography>
                <Typography><strong>PB Last 6 Runs:</strong> {runner.pbLast6Runs}</Typography>

            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
};

export default RunnerDetailsModal;