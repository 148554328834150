// src/services/api.ts
import axios from 'axios';

const BASE_URL = process.env.REACT_APP_API_URL || 'https://localhost:8000/api';


const api = axios.create({
    baseURL: BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

// Add a request interceptor to include the auth token
api.interceptors.request.use((config) => {
    const token = localStorage.getItem('authToken');
    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
});

export const requestLoginCode = async (email: string) => {
    const response = await api.post('/auth/request-code', { email });
    return response.data;
};

export const login = async (email: string, loginCode: string) => {
    const response = await api.post('/auth/login', { email, loginCode });
    const { token } = response.data;
    localStorage.setItem('authToken', token);
    localStorage.setItem('user', JSON.stringify(email));
    console.log('Auth token set');
    return response.data;
};

export const logout = async () => {
    const response = await api.post('/auth/logout');
    return response.data;
};


export const getRaces = async (params?: any) => {
    const response = await api.get('/races', { params });
    return response.data;
};

export const getRaceDetails = async (id: string) => {
    const response = await api.get(`/races/${id}`);
    return response.data;
};

export const getRunners = async (raceId: string) => {
    const response = await api.get(`/races/${raceId}/runners`);
    return response.data;
};

export const getRunnerDetails = async (id: string) => {
    const response = await api.get(`/runners/${id}`);
    return response.data;
};

export const getRaceMarkets = async (raceId: string) => {
    const response = await api.get(`/races/${raceId}/markets`);
    return response.data;
};
export const getTrainers = async () => {
    const response = await api.get('/trainers');
    return response.data;
};

export const getJockeys = async () => {
    const response = await api.get('/jockeys');
    return response.data;
};

export const getUserProfile = async () => {
    const response = await api.get('/user/profile');
    return response.data;
};

export const getCurrentUser = async () => {
    const response = await api.get('/user/profile');
    return response.data;
};


export const getMeetings = async (params?: { date?: string; timezone?: string }) => {
    const response = await api.get('/meetings', { params });
    return response.data;
};

export const getRaceMaps = async (raceId: string) => {
    const response = await api.get(`/races/${raceId}/maps`);
    return response.data;
};

export const getRacePaces = async (raceId: string) => {
    const response = await api.get(`/races/${raceId}/paces`);
    return response.data;
};

export default api;
