// src/pages/RaceDetailsPage.tsx
import React, { useState, useEffect, useMemo } from 'react';
import { useParams, Link as RouterLink } from 'react-router-dom';
import {
    Typography,
    Paper,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Link,
    Tabs,
    Tab,
    Box,
    Button,
    Tooltip
} from '@mui/material';
import { getRaceDetails, getRunners, getRaceMarkets, getRaceMaps, getRacePaces } from '../services/api';
import RaceMap from '../components/RaceMap';
import RunnerDetailsModel from '../components/RunnerDetailsModel'

interface Runner {
    id: string;
    number: number;
    name: string;
    barrier: number;
    trainer: string;
    jockey: string;
    trainerName: string;
    jockeyName: string;
    form: string;
    rating: number;
    oddsRating: number;
    predRating: number;
    dwrtRating: number;
    status: boolean;
    pbLast15Runs: number;
    pbLast12Runs: number;
    pbLast6Runs: number;
}


interface RaceDetails {
    id: string;
    uniqueId: string;
    date: string;
    venue: string;
    raceNumber: number;
    class: string;
    tcond: string;
    distance: number;
    raceName: string;
    prizemoney: string;
}

interface Market {
    marketType: string;
    prices: {
        [runnerNumber: number]: number;
    };
}

interface RunnerMarket {
    number: number;
    name: string;
    prices: {
        [marketType: string]: number;
    };
    status: boolean;
}

interface RaceMaps {
    vpace: {
        type: string;
        data: {
            runner: number;
            offset: number;
            value: number;
        }[];
    }[];
    racepace: {
        type: string;
        data: string;
    }[];
}

interface PaceData {
    type: string;
    runners: {
        runner: string;
        number: number;
    }[];
}

const RaceDetailsPage = () => {
    const { id } = useParams<{ id: string }>();
    const [raceDetails, setRaceDetails] = useState<RaceDetails | null>(null);
    const [runners, setRunners] = useState<Runner[]>([]);
    const [markets, setMarkets] = useState<Market[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [tabValue, setTabValue] = useState(0);
    const [raceMaps, setRaceMaps] = useState<RaceMaps | null>(null);
    const [paces, setPaces] = useState<PaceData[]>([]);

    useEffect(() => {
        const fetchRaceData = async () => {
            try {
                if (id) {
                    const [raceData, runnersData, marketsData, mapsData, pacesData] = await Promise.all([
                        getRaceDetails(id),
                        getRunners(id),
                        getRaceMarkets(id),
                        getRaceMaps(id),
                        getRacePaces(id)
                    ]);
                    setRaceDetails(raceData);
                    setRunners(runnersData);
                    setMarkets(marketsData);
                    setRaceMaps(mapsData);
                    setPaces(pacesData);
                }
                setLoading(false);
            } catch (err) {
                setError('Failed to fetch race data');
                setLoading(false);
            }
        };
        fetchRaceData();
    }, [id]);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    const [selectedRunner, setSelectedRunner] = useState<Runner | null>(null);
    const [runnerDetailsOpen, setRunnerDetailsOpen] = useState(false);

    const handleOpenRunnerDetails = (runner: Runner) => {
        setSelectedRunner(runner);
        setRunnerDetailsOpen(true);
    };

    const handleCloseRunnerDetails = () => {
        setRunnerDetailsOpen(false);
        setSelectedRunner(null);
    };

    const restructuredMarkets = useMemo(() => {
        const runnerMarkets: { [runnerNumber: number]: RunnerMarket } = {};
        const marketTypes: string[] = [];

        markets.forEach(market => {
            if (!marketTypes.includes(market.marketType)) {
                marketTypes.push(market.marketType);
            }

            Object.entries(market.prices).forEach(([runnerNumber, price]) => {
                const number = parseInt(runnerNumber, 10);
                const runner = runners.find(r => r.number === number);
                if (runner && !runner.status) { // Only include non-scratched runners
                    if (!runnerMarkets[number]) {
                        runnerMarkets[number] = {
                            number,
                            name: runner.name,
                            prices: {},
                            status: runner.status
                        };
                    }
                    runnerMarkets[number].prices[market.marketType] = price;
                }
            });
        });

        return {
            marketTypes,
            runnerMarkets: Object.values(runnerMarkets).sort((a, b) => a.number - b.number)
        };
    }, [markets, runners]);

    const calculateMarketPercentage = (marketType: string): number => {
        return restructuredMarkets.runnerMarkets.reduce((total, runner) => {
            if (!runner.status && runner.prices[marketType]) {
                return total + ((runner.prices[marketType] - 1) / 100);
            }
            return total;
        }, 0) * 100;
    };


    const runnerInfo = useMemo(() => {
        return runners
            .filter(runner => !runner.status) // Exclude scratched runners
            .map(runner => ({
                number: runner.number,
                name: runner.name,
                odds: markets.find(m => m.marketType === 'DOM')?.prices[runner.number] || 0,
            }));
    }, [runners, markets]);


    const combinedPaceData = useMemo(() => {
        const paceTypes = paces.map(pace => pace.type);
        const runnerPaces: { [key: string]: { [key: string]: number } } = {};

        paces.forEach(pace => {
            pace.runners.forEach(runner => {
                if (!runnerPaces[runner.runner]) {
                    runnerPaces[runner.runner] = {};
                }
                runnerPaces[runner.runner][pace.type] = runner.number;
            });
        });

        return { paceTypes, runnerPaces };
    }, [paces]);

    const scratchedStyle = {
        textDecoration: 'line-through',
        color: 'grey',
    };

    if (loading) return <Typography>Loading...</Typography>;
    if (error) return <Typography color="error">{error}</Typography>;
    if (!raceDetails) return <Typography>Race not found</Typography>;

    return (
        <Paper elevation={3} sx={{ padding: 3 }}>
            <Typography variant="h4" gutterBottom>
                {raceDetails.raceName}
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <Typography><strong>Date:</strong> {new Date(raceDetails.date).toLocaleDateString()}</Typography>
                    <Typography><strong>Venue:</strong> {raceDetails.venue}</Typography>
                    <Typography><strong>Race Number:</strong> {raceDetails.raceNumber}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography><strong>Class:</strong> {raceDetails.class}</Typography>
                    <Typography><strong>Track Condition:</strong> {raceDetails.tcond}</Typography>
                    <Typography><strong>Distance:</strong> {raceDetails.distance}m</Typography>
                    <Typography><strong>Prizemoney:</strong> {raceDetails.prizemoney}</Typography>
                </Grid>
            </Grid>

            <Box sx={{ borderBottom: 1, borderColor: 'divider', mt: 3 }}>
                <Tabs value={tabValue} onChange={handleTabChange}>
                    <Tab label="Runners" />
                    <Tab label="Markets" />
                    <Tab label="Pace Information" />
                    <Tab label="Maps" />
                </Tabs>
            </Box>

            <TabPanel value={tabValue} index={0}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Number</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>Barrier</TableCell>
                                <TableCell>Jockey</TableCell>
                                <TableCell>Trainer</TableCell>
                                <TableCell>Details</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {runners.map((runner) => (
                                <TableRow key={runner.id}>
                                    <TableCell style={runner.status ? scratchedStyle : {}}>{runner.number}</TableCell>
                                    <TableCell style={runner.status ? scratchedStyle : {}}>{runner.name}</TableCell>
                                    <TableCell style={runner.status ? scratchedStyle : {}}>{runner.barrier}</TableCell>
                                    <TableCell style={runner.status ? scratchedStyle : {}}>{runner.jockeyName}</TableCell>
                                    <TableCell style={runner.status ? scratchedStyle : {}}>{runner.trainerName}</TableCell>
                                    <TableCell>
                                        <Button variant="outlined" onClick={() => handleOpenRunnerDetails(runner)}>
                                            View Details
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </TabPanel>


            <TabPanel value={tabValue} index={1}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Number</TableCell>
                                <TableCell>Name</TableCell>
                                {restructuredMarkets.marketTypes.map(marketType => (
                                    <TableCell key={marketType}>{marketType}</TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {restructuredMarkets.runnerMarkets.map((runner) => (
                                <TableRow key={runner.number}>
                                    <TableCell style={runner.status ? scratchedStyle : {}}>
                                        {runner.number}
                                    </TableCell>
                                    <TableCell style={runner.status ? scratchedStyle : {}}>
                                        {runner.name}
                                    </TableCell>
                                    {restructuredMarkets.marketTypes.map(marketType => (
                                        <Tooltip title={runner.prices[marketType] ? `${runner.prices[marketType].toFixed(2)}` : '-'} arrow>
                                            <TableCell key={marketType} style={runner.status ? scratchedStyle : {}}>
                                                {runner.prices[marketType] ? `${(1 / (runner.prices[marketType] - 1) * 100).toFixed(2) }` : '-'}
                                            </TableCell>
                                        </Tooltip>
                                    ))}
                                </TableRow>
                            ))}
                            <TableRow>
                                <TableCell colSpan={2}>Market %</TableCell>
                                {restructuredMarkets.marketTypes.map(marketType => (
                                    <TableCell key={marketType}>
                                        {calculateMarketPercentage(marketType).toFixed(2)}%
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </TabPanel>


            <TabPanel value={tabValue} index={2}>
                <Typography variant="h6" gutterBottom>
                    Pace Information
                </Typography>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>#</TableCell>
                                <TableCell>Runner Name</TableCell>
                                {combinedPaceData.paceTypes.map((paceType) => (
                                    <TableCell key={paceType}>{paceType}</TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Object.entries(combinedPaceData.runnerPaces).map(([runnerNumber, paces]) => {
                                const runnerDetails = runners.find(r => r.number === parseInt(runnerNumber));
                                return (
                                    <TableRow key={runnerNumber}>
                                        <TableCell>{runnerNumber}</TableCell>
                                        <TableCell>{runnerDetails?.name || 'Unknown'}</TableCell>
                                        {combinedPaceData.paceTypes.map((paceType) => (
                                            <TableCell key={paceType}>
                                                {paces[paceType] ? `${(paces[paceType]).toFixed(2)}` : '-'}
                                            </TableCell>

                                        ))}
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </TabPanel>

            <TabPanel value={tabValue} index={3}>
                {raceMaps ? (
                    <RaceMap
                        vpace={raceMaps.vpace}
                        racepace={raceMaps.racepace}
                        runners={runnerInfo}
                    />
                ) : (
                    <Typography>No map data available</Typography>
                )}
            </TabPanel>

            <RunnerDetailsModel
                open={runnerDetailsOpen}
                onClose={handleCloseRunnerDetails}
                runner={selectedRunner}
            />
        </Paper>
    );
};

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

export default RaceDetailsPage;
