// src/pages/HomePage.tsx
import React from 'react';
import Typography from '@mui/material/Typography';

const HomePage = () => {
    return (
        <Typography variant="h4" component="h1" gutterBottom>
            Welcome to GaitMate
        </Typography>
    );
};

export default HomePage;
